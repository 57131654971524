<template>
  <page-layout>
    <a-card>
      <a-form-model :model="form" layout="inline">
        <a-row type="flex" justify="space-between" align="middle">
          <a-col>
            <a-form-model-item label="发票类型">
              <a-select
                v-model="form.type"
                style="width: 200px"
                placeholder="请选择"
                @change="getList"
              >
                <a-select-option
                  :value="item.value"
                  :key="item.value"
                  v-for="item in typeList"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="开票时间检索">
              <a-range-picker @change="onChange" style="width: 250px" />
            </a-form-model-item>
          </a-col>
          <a-col>
            <a-input-search
              placeholder="输入发票编号、订单编号搜索发票"
              style="width: 290px"
              @search="onSearch"
            />
          </a-col>
        </a-row>
      </a-form-model>
      <a-alert
        :message="`您共有${total}张发票！`"
        type="success"
        show-icon
        closable
        style="margin: 12px 0"
      >
        <template slot="icon">
          <a-icon type="exclamation-circle" class="circle_icon" />
        </template>
      </a-alert>
      <a-spin :spinning="loadding">
        <div class="box_list">
          <div
            :class="[
              'box',
              item.type == 2 ? 'active' : '',
              item.status == 4 ? 'gray' : '',
            ]"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="box_top">
              <p>发票号码：{{ item.invoice_number }}</p>
              <p>
                发票类型：{{ typeList.find((i) => i.value == item.type).label }}
              </p>
              <p>开票日期：{{ item.invoice_time }}</p>
              <p v-if="item.type == 2">请联系财务获取纸质发票</p>
            </div>
            <div class="box_bottom">
              <a-button
                class="btn"
                @click="openDrawer(item)"
                :disable="item.status == 4"
                >商品明细</a-button
              >
              <a-button
                type="primary"
                class="btn"
                v-if="item.type == 2"
                :disable="item.status == 4"
                @click="getInvoicePrintInfo(item)"
              >
                查看发票
              </a-button>
              <a-button
                type="primary"
                class="btn"
                v-else
                :disable="item.status == 4"
                @click="getInvoicePrintInfo(item)"
              >
                下载发票
              </a-button>
            </div>
          </div>
        </div>
      </a-spin>

      <a-empty v-if="!list.length" />
      <div class="text_right">
        <a-pagination
          showQuickJumper
          show-size-changer
          :default-current="form.page"
          :total="total"
          @showSizeChange="changePage"
          @change="changePage"
        />
      </div>
      <a-drawer
        title="商品明细"
        placement="right"
        :closable="true"
        :visible="visible"
        :width="800"
        @close="onClose"
      >
        <a-card>
          <div class="detail_num">
            <span>共包含{{ orderCount }}个订单</span>
            (
            <router-link
              v-for="(i, index) in orderList"
              :key="index"
              :to="'/bookBuy/order/orderDetail?id=' + i"
              style="border-bottom: solid 1px; margin: 0 5px"
            >
              {{ i }}
            </router-link>
            ) , {{ productCount }}个商品
          </div>
          <a-table
            :pagination="false"
            :columns="columns"
            :data-source="data"
            class="mt16"
          >
            <template slot="index" slot-scope="text, record, index">{{
              ++index
            }}</template>
            <template slot="product" slot-scope="text, record">
              <div class="store_box">
                <img :src="record.images" alt="" />
                <div class="store_info">
                  <p class="store_title">{{ record.storeName }}</p>
                  <p>
                    <span class="yu" v-if="record.isOversold == 1">预</span>
                    <span>{{ record.specName }}</span>
                  </p>
                </div>
              </div>
              <p class="mb0 isbn">
                <span>ISBN:{{ record.isbn }}</span>
                <span>编码:{{ record.barNum }}</span>
              </p>
            </template>
          </a-table>
        </a-card>
      </a-drawer>
    </a-card>
  </page-layout>
</template>

<script>
const columns = [
  {
    dataIndex: "index",
    title: "编号",
    key: "index",
    width: 60,
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    dataIndex: "invoiceName",
    title: "发票标题",
    align: "center",
    key: "invoiceName",
    width: 120,
  },
  {
    dataIndex: "product",
    title: "商品",
    key: "product",
    scopedSlots: { customRender: "product" },
    width: 240,
  },
  {
    dataIndex: "number",
    title: "数量",
    align: "center",

    key: "number",
  },
  {
    dataIndex: "price",
    align: "center",
    title: "价格",
    customRender: (text) => {
      return "￥" + text;
    },
    key: "price",
  },
  {
    align: "center",
    dataIndex: "totalPrice",
    customRender: (text) => {
      return "￥" + text;
    },
    title: "合计",
    key: "totalPrice",
  },
];
import PageLayout from "@/layouts/PageLayout";
import { lookInvoiceNumber, invoiceNumberProduct } from "@/api/invoice";
export default {
  components: { PageLayout },
  name: "",
  data() {
    return {
      form: {
        page: 1,
        limit: 10,
      },
      list: [],
      total: 0,
      typeList: [
        {
          value: "0",
          label: "不限",
        },
        {
          value: "1",
          label: "电子普通发票",
        },
        {
          value: "2",
          label: "增值税专用发票",
        },
        {
          value: "3",
          label: "数电专票",
        },
        {
          value: "4",
          label: "数电普票",
        },
      ],
      loadding: false,
      visible: false,
      detailForm: {},
      columns,
      data: [],
      orderCount: 0,
      productCount: 0,
      orderList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onChange(date, dateString) {
      console.log(date, dateString);
      this.form.start_time = dateString[0];
      this.form.end_time = dateString[1];
      this.form.page = 1;
      this.getList();
    },
    getInvoicePrintInfo(record) {
      if (record.type == 2) {
        this.$message.warning("请联系约读书房总部获取纸质发票");
      } else {
        window.open(record.c_paper_pdf_url || record.download_url);
      }
    },
    getDetail(number, fn) {
      invoiceNumberProduct(number).then((res) => {
        this.data = res.data.data.productList;
        this.productCount = res.data.data.productCount;
        this.orderCount = res.data.data.orderCount;
        this.orderList = res.data.data.invoiceOrderSn
          .split(",")
          .filter(
            (item) =>
              item !== "" &&
              item !== null &&
              item !== undefined &&
              item === item
          );
        fn();
      });
    },
    openDrawer(item) {
      const hide = this.$message.loading("加载中...", 0);
      this.getDetail(item.invoice_number, () => {
        this.visible = true;
        hide();
      });
    },
    onClose() {
      this.visible = false;
    },
    getList() {
      this.loadding = true;
      lookInvoiceNumber(this.form)
        .then((res) => {
          this.list = res.data.data.list;
          this.total = res.data.data.count;
        })
        .finally(() => {
          this.loadding = false;
        });
    },
    changePage(page, pageSize) {
      this.form.page = page;
      this.form.limit = pageSize;
      this.getList();
    },
    onSearch(e) {
      this.form.keyword = e;
      this.getList();
    },
    goOrder(order) {
      console.log(order);
    },
  },
};
</script>
<style scoped lang="less">
.ant-alert-success {
  background-color: #f3fdf9;
  border: 1px solid #3ab887;
  color: #3ab887 !important;

  .circle_icon {
    color: #3ab887;
  }
}

div /deep/ .ant-alert-icon {
  top: 8.5px;
}

.box_list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 40px;

  .box {
    width: 228px;
    height: 243px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;

    .box_top {
      background-color: #eafcf5;
      padding: 31px 19px 0 19px;
      height: 133px;

      p {
        margin-bottom: 0;
        font-size: 12px;

        color: #3ab887;

        &:nth-of-type(1) {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        &:nth-of-type(2) {
          margin-bottom: 5px;
        }
        &:nth-of-type(4) {
          color: #999999;
          // margin-bottom: 5px;
        }
      }
    }

    .box_bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 19px;

      .btn {
        width: 196px;

        &:first-of-type {
          margin-bottom: 9px;
          border: 1px solid @primary-color;
          color: @primary-color;
        }
      }
    }
  }

  .active {
    .box_top {
      background-color: rgba(255, 141, 26, 0.04285714285714292);

      p {
        color: #ff8d1a;
      }
    }

    .box_bottom {
      .btn:first-of-type {
        border: 1px solid #ff8d1a;
        color: #ff8d1a;
      }

      .btn:last-of-type {
        background-color: #ff8d1a;
        border: 1px solid #ff8d1a;
      }
    }
  }
}

.text_right {
  margin-top: 24px;
  text-align: right;
}

div /deep/ .ant-drawer-wrapper-body {
  background-color: #f7f7f7;
}

.detail_num {
  //color: #3ab887;
  font-size: 14px;
  font-weight: bold;
}

.mt16 {
  margin-top: 16px;
}

.store_box {
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    flex-shrink: 0;
  }

  .store_info {
    width: 180px;
    font-size: 12px;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }

    .store_title {
      color: #333333;
    }

    .yu {
      padding: 2px;
      background-color: #ff8d1a;
      color: #fff;
      border-radius: 4px;
      margin-right: 8px;
    }
  }
}

.isbn {
  color: #3ab887;
  font-size: 12px;
  display: flex;
  gap: 8px;
}

.mb0 {
  margin-bottom: 0;
}
.gray {
  filter: grayscale(1);
}
</style>
